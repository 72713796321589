import { useBillyRouter } from '@/components/route/useBillyRouter'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuTrigger,
} from '@/components/ui/context-menu'
import { Feature } from '@/generated/graphql'
import { useLineItemEditActions } from '@/pageComponents/orders/LineItemsEditTable/LineItemEditContextProvider'

export function LineItemTableContextMenu(props) {
  const { onSingleBulkApply } = useLineItemEditActions()
  const router = useBillyRouter()
  // TODO: remove when out of lab
  const isLabEnabled = useDynamicFeatureFlag(Feature.UiLaboratory)
  const show = isLabEnabled && router.query.experiment === 'bulk-apply'

  return show ? (
    <ContextMenu>
      <ContextMenuTrigger>{props.children}</ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem inset disabled>
          Revert
        </ContextMenuItem>
        <ContextMenuSub>
          <ContextMenuSubTrigger inset>Bulk Apply</ContextMenuSubTrigger>
          <ContextMenuSubContent>
            <ContextMenuItem onClick={() => onSingleBulkApply?.('quantity')}>Quantity</ContextMenuItem>
            <ContextMenuItem onClick={() => onSingleBulkApply?.('discount')}>Discount</ContextMenuItem>
            <ContextMenuItem onClick={() => onSingleBulkApply?.('total-sell-amount')}>
              Total Sell Amount
            </ContextMenuItem>
            <ContextMenuItem onClick={() => onSingleBulkApply?.('price-override')}>Price Override</ContextMenuItem>
            <ContextMenuSeparator />
            <ContextMenuItem>Multi Attributes</ContextMenuItem>
            <ContextMenuSeparator />
            <ContextMenuItem>Developer Tools</ContextMenuItem>
          </ContextMenuSubContent>
        </ContextMenuSub>
      </ContextMenuContent>
    </ContextMenu>
  ) : (
    <>{props.children}</>
  )
}
