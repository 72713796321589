import { useModal, useModalsContext, WithModalParams } from '@/components/state/context/modalsContext'
import { z } from 'zod'
import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import {
  InputOrderAttributesUpdateRequest,
  OrderDetailFragment,
  useUpdateOrderAttributesMutation,
} from '@/generated/graphql'
import { BillyTsForm } from '@/components/BillyTSForm/BillyTsForm'
import React, { useCallback, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material'
import ActionButton from '@/components/button/actionButton'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSnackbarHandler } from '@/components/SnackbarHandler/SnackbarHandler'

const OrderAttributesEditSchema = z.object({
  name: z.string().describe('Order Name').optional(),
})

type OrderAttributesDialogProps = {
  orderDetail: OrderDetailFragment | undefined
  refresh?: () => void
}
type DialogProps = WithModalParams & OrderAttributesDialogProps

const formId = 'OrderAttributesEditDialog'

function OrderAttributesEditDialog({ open = false, onClose, refresh, orderDetail, onSubmit }: DialogProps) {
  const errorHandler = useErrorHandler()
  const snackbarHandler = useSnackbarHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onFormSubmit = (data: z.infer<typeof OrderAttributesEditSchema>) => {
    async function doAsync() {
      setIsSubmitting(true)
      await onSubmit?.(data)
      setIsSubmitting(false)
      onClose?.()
      snackbarHandler.successAlert('Order Details updated successfully')
      refresh?.()
    }
    doAsync().catch((error) => {
      setIsSubmitting(false)
      errorHandler(error)
    })
  }

  const form = useForm<z.infer<typeof OrderAttributesEditSchema>>({
    resolver: zodResolver(OrderAttributesEditSchema),
  })
  const handleSubmitCallback = async () => await form.handleSubmit(onFormSubmit)()

  return (
    <Dialog open={!!open} onClose={onClose} fullWidth>
      <DialogTitle>Update Order Details</DialogTitle>
      <Divider />
      <DialogContent>
        <BillyTsForm
          form={form}
          formProps={{ id: formId }}
          schema={OrderAttributesEditSchema}
          onSubmit={handleSubmitCallback}
          defaultValues={{
            name: orderDetail?.name || '',
          }}
          props={{
            name: {
              layout: { xs: 12 },
            },
          }}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          key={'Save'}
          buttonData={{
            label: 'Save',
            loading: isSubmitting,
            buttonProps: { type: 'submit', form: formId },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useOrderAttributesEditDialog(modalProps: DialogProps) {
  const [, , toggleModal] = useModalsContext()
  const { orderDetail } = modalProps

  const [, updateOrderAttributes] = useUpdateOrderAttributesMutation()

  const onSubmit = useCallback(
    async (data: z.infer<typeof OrderAttributesEditSchema>) => {
      const orderAttributes: InputOrderAttributesUpdateRequest = {
        name: data.name,
      }
      await updateOrderAttributes({
        orderId: orderDetail?.id as string,
        updateRequest: orderAttributes,
      })
    },
    [updateOrderAttributes, orderDetail?.id]
  )

  useModal<DialogProps>({
    component: OrderAttributesEditDialog,
    schema: {
      key: formId,
      modalProps: {
        ...modalProps,
        onSubmit,
      },
    },
  })
  return useCallback(() => {
    toggleModal(formId)
  }, [toggleModal])
}
