import InnerPageContainer from '@/components/layout/innerPageContainer'
import { NavLayout } from '@/components/layout/navLayout'
import { isCurrentEnvironmentOneOf } from '@/util/isEnv'
import buildLogger from '@/util/logger'
import { Grid } from '@mui/material'
import Head from 'next/head'
import Image from 'next/image'

import BillyLinkCard, { LinkCardProps } from '@/components/card/billyLinkCard'
import WithDynamicFeatureFlag from '@/components/WithDynamicFeatureToggle/WithDynamicFeatureToggle'
import { Feature } from '@/generated/graphql'
import { ScienceOutlined } from '@mui/icons-material'
const logger = buildLogger('LaboratoryPage')

export const LABORATORY_ROUTES: (LinkCardProps & { feature?: Feature | undefined })[] = [
  {
    title: 'Order Bulk Apply',
    subheader: 'Experiment on order order items table and ability bulk apply attributes.',
    url: 'orders/new?experiment=bulk-apply',
  },
  {
    title: 'Charts',
    subheader: 'Experiment on charts',
    url: 'laboratory/ui-charts',
  },
  {
    title: 'Quote Builder',
    subheader: 'Experiment for showing off quote builder bare bones UI.',
    url: 'laboratory/quote-builder',
    feature: Feature.QuoteBuilder,
  },
  {
    title: 'DataDog Alert Test',
    subheader: 'Experiment different page behavior when there is an error.',
    url: 'laboratory/datadog-alerts',
    hide: !isCurrentEnvironmentOneOf('local', 'dev2'),
  },
].filter((route) => !route.hide)

export function LaboratoryPage(): JSX.Element {
  const breadcrumbs = [
    {
      label: '',
      link: '/',
      icon: <Image src="/icons/home-sm.svg" alt="home" width={20} height={20} />,
    },
    {
      label: 'Laboratory',
      link: '/laboratory',
    },
  ]

  return (
    <>
      <Head>
        <title>Laboratory | Subskribe</title>
      </Head>
      <NavLayout breadcrumbs={breadcrumbs}>
        <InnerPageContainer>
          <Grid container rowGap={3} columnSpacing={3}>
            {LABORATORY_ROUTES.map((link) => (
              <>
                {Object.prototype.hasOwnProperty.call(link, 'feature') ? (
                  <WithDynamicFeatureFlag feature={link.feature as Feature} status="enabled">
                    <Grid item xs={12} md={6} lg={4} key={link.url}>
                      <BillyLinkCard avatar={<ScienceOutlined width={28} height={28} color="primary" />} {...link} />
                    </Grid>
                  </WithDynamicFeatureFlag>
                ) : (
                  <Grid item xs={12} md={6} lg={4} key={link.url}>
                    <BillyLinkCard avatar={<ScienceOutlined width={28} height={28} color="primary" />} {...link} />
                  </Grid>
                )}
              </>
            ))}
          </Grid>
        </InnerPageContainer>
      </NavLayout>
    </>
  )
}
