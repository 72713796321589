import BillyCard from '@/components/card/billyCard'
import { CustomFieldsCard } from '@/components/CustomFields/CustomFieldsCard'
import { useLoggerEffect } from '@/components/hooks/useLoggerEffect'
import { useBillyRouter } from '@/components/route/useBillyRouter'
import { OrderPageSalesRoomCustomFieldsCard } from '@/components/SalesRoom/OrderPageSalesRoomCustomFieldsCard'
import { useUserTenantSession } from '@/components/UserTenantSessionProvider/UserTenantSessionContext'
import { CustomFieldParentType, OpportunityFragment, OrderDetailFragment, TenantJobType } from '@/generated/graphql'
import { IncludedTermsViewJotai } from '@/pageComponents/orders/IncludedTerms/IncludedTermsView'
import { OrderItemPreview } from '@/pageComponents/orders/OrderItemPreview'
import { OrderDetailsViewCard } from '@/pageComponents/orders/ViewOrderPage/OrderDetailsViewCard'
import { PredefinedDiscountsViewCard } from '@/pageComponents/orders/ViewOrderPage/PredefinedDiscountsViewCard'
import { isOrderExecutedOrCancelled, StatusStepper } from '@/pages/orders/[orderId]'
import buildLogger from '@/util/logger'
import { canMutateOrder, isAdminRole } from '@/util/roleUtils'
import { CardContent, CardHeader, Grid, Typography } from '@mui/material'
import React, { createContext, useContext } from 'react'
import { ObjectJobViewTable } from '@/pageComponents/orders/ViewOrderPage/ObjectJobViewTable'
import { OrderTimelineCard } from '@/pageComponents/orders/EditOrderPage/cards/EditOrderTimelineCard/OrderTimelineCard'

const logger = buildLogger('OrderViewPageContent')

type OrderViewPageContentProps = {
  orderData?: OrderDetailFragment
  updateOrder?: () => void
  opportunityData?: OpportunityFragment
  hasSalesforceIntegration?: boolean
  hasHubSpotIntegration?: boolean
}

const OrderViewPageContentContext = createContext<OrderViewPageContentProps>({})
export const useOrderViewPageContent = () => {
  const context = useContext(OrderViewPageContentContext)
  return context
}

export const OrderViewPageContentProvider = ({
  children,
  value,
}: {
  children: React.ReactNode
  value: OrderViewPageContentProps
}) => {
  return <OrderViewPageContentContext.Provider value={value}>{children}</OrderViewPageContentContext.Provider>
}

export function OrderViewPageContent() {
  const handleUpdateOrder = () => {
    logger.info('Order updated trigger')
    updateOrder?.()
  }

  const { orderData, updateOrder, opportunityData, hasSalesforceIntegration, hasHubSpotIntegration } =
    useOrderViewPageContent()
  const router = useBillyRouter()
  const orderId = router.query.orderId as string
  const userTenantSession = useUserTenantSession()
  const role = userTenantSession.currentUser.role

  useLoggerEffect(logger)

  return orderData ? (
    <Grid container direction="column" wrap="nowrap" spacing={3}>
      <Grid item>
        <StatusStepper order={orderData} />
      </Grid>

      <OrderDetailsViewCard
        orderData={orderData}
        hasHubSpotIntegration={hasHubSpotIntegration}
        hasSalesforceIntegration={hasSalesforceIntegration}
        opportunityData={opportunityData}
        orderId={orderId}
      />
      <Grid item>
        <OrderTimelineCard readonly />
      </Grid>
      <CustomFieldsCard
        customFieldType={CustomFieldParentType.Order}
        parentObjectId={orderId}
        canEditCustomFields={!isOrderExecutedOrCancelled(orderData) && canMutateOrder(role)}
      />
      <OrderPageSalesRoomCustomFieldsCard orderId={orderId} />
      <OrderItemPreview orderData={orderData} orderId={orderId} updateOrder={handleUpdateOrder} role={role} />

      <PredefinedDiscountsViewCard orderData={orderData} />
      {orderData?.documentMasterTemplate?.name && (
        <Grid item>
          <BillyCard>
            <CardHeader title="Document Template" />
            <CardContent>
              <Typography>{orderData?.documentMasterTemplate?.name}</Typography>
            </CardContent>
          </BillyCard>
        </Grid>
      )}
      <IncludedTermsViewJotai refresh={handleUpdateOrder} />
      {isAdminRole(role) && (
        <ObjectJobViewTable
          objectId={orderId}
          jobTypes={[
            TenantJobType.SalesforceOrderSync,
            TenantJobType.HubspotOrderSync,
            TenantJobType.CrmOrderDeletionSync,
          ]}
          searchType={'Order'}
          title={'Order Tasks'}
        />
      )}
    </Grid>
  ) : (
    <></>
  )
}
